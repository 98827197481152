import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ToolBar from "./Toolbar";
import propTypes from "prop-types";
import { withTheme } from "styled-components";
import minTwoDigits from "helpers/minTwoDigits";
import SeeMoreComponent from "./SeeMoreComponent";
import SelectedEventComponent from "./SelectedEventComponent";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import FileRightClick from "../../digitalResources/components/FileRightClick";
import downloadFile from "helpers/downloadFile";
import parseHTMLText from "helpers/parseHTMLText";
import { successToast } from "helpers/toastMsgs";

export class CalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      eventsList: [],
      eventsListParam: null,
      selectedEvent: null,
      seeMore: false,
      seeMoreEvents: null,
      paramDate: null,
      currentDate: new Date(),
      isAddToCalMenuOpen: false,
      contextMenuXY: {},
    };
  }

  menuRef = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    let paramDate = null;
    let selectedEvent = null;
    const eventsList = nextProps.events(prevState.eventsListParam);
    if (prevState.eventsListParam) {
      successToast(
        `${eventsList.length} event${eventsList.length > 1 ? "s" : ""} found.`,
        true
      );
    }
    const { day, month, year, eventId } = nextProps.match.params;
    if (day && month && year && eventId) {
      paramDate = new Date(
        `${year}-${minTwoDigits(month)}-${minTwoDigits(day)}T12:00:00`
      );
      selectedEvent =
        eventsList.filter(event => Number(event.id) === Number(eventId))[0] ||
        null;
    }
    return {
      ...prevState,
      eventsListParam: null,
      paramDate: paramDate,
      currentDate: paramDate || new Date(),
      eventsList: eventsList,
      selectedEvent: selectedEvent || prevState.selectedEvent,
    };
  }

  componentDidMount() {
    this.getEvents(this.state.currentDate);
  }

  handleEventSelected = event => {
    this.clearParams(event);
    this.setState({
      selectedEvent: event,
      seeMore: false,
      seeMoreEvents: null,
    });
  };

  clearParams = event => {
    if (!event && !!this.state.paramDate) {
      this.setState({
        selectedEvent: null,
        seeMore: false,
        seeMoreEvents: null,
        paramDate: null,
      });
      this.props.history.push("/calendar");
    }
  };

  handleSeeMoreSelected = events => {
    this.setState({ seeMore: true, seeMoreEvents: events });
  };

  handleMoreEventsClose = () => {
    this.setState({ seeMore: false });
  };

  handleEventFilter = param => {
    param
      ? this.setState({ eventsListParam: param })
      : this.setState({ eventsListParam: null });
  };

  handleOnNavigate = date => {
    this.getEvents(date);
  };

  components = {
    header: header => {
      return (
        <div>
          <p>{header.date.toLocaleDateString("en-GB", { weekday: "short" })}</p>
          {header.label.length > 3 && <p>{header.date.getDate()}</p>}
        </div>
      );
    },
    toolbar: toolbar => {
      return (
        <ToolBar
          filterEvents={this.handleEventFilter}
          getEvents={this.props.getEvents}
          paramDate={this.state.paramDate}
          toolbar={toolbar}
        />
      );
    },
  };

  getEvents = date => {
    this.props.getEvents({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    });
  };

  formats = {
    timeGutterFormat: "HH",
    eventTimeRangeFormat: ({ start, end }, culture, local) => {
      const startTime = local.format(start, "HH:mm", culture);
      const endTime = local.format(end, "HH:mm", culture);

      return `${startTime} to ${endTime}`;
    },
  };

  setIsAddToCalMenuOpen = isOpen => {
    this.setState({ isAddToCalMenuOpen: isOpen });
  };

  addToCalOptions = [
    {
      title: "Google Calendar",
      icon: () => <div />,
      fn: () => {
        const url = `https://www.google.com/calendar/render?action=TEMPLATE&
text=${this.state.selectedEvent.title}&
dates=${moment(this.state.selectedEvent.start).format(
          "YYYYMMDDTHHmmss"
        )}Z/${moment(this.state.selectedEvent.end).format("YYYYMMDDTHHmmss")}Z&
details=${parseHTMLText(this.state.selectedEvent.details, "div")}&
location=${this.state.selectedEvent.location}&
sf=true&output=xml`;
        window.open(url);
        this.setIsAddToCalMenuOpen(false);
      },
    },
    {
      title: "Outlook Calendar",
      icon: () => <div />,
      fn: () => {
        this.generateICS(this.state.selectedEvent);
        this.setIsAddToCalMenuOpen(false);
      },
    },
    {
      title: "iCalendar",
      icon: () => <div />,
      fn: () => {
        this.generateICS(this.state.selectedEvent, "text/calendar");
        this.setIsAddToCalMenuOpen(false);
      },
    },
    {
      title: "ICS File",
      icon: () => <div />,
      fn: () => {
        this.generateICS(this.state.selectedEvent);
        this.setIsAddToCalMenuOpen(false);
      },
    },
  ];

  generateICS(data, mime = "text/plain") {
    const mDateStart = moment(data.start);
    const mDateEnd = moment(data.end);
    let cal = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//AgentsPortal//Event
TZOFFSETFROM:+0000
TZOFFSETTO: ${minTwoDigits(new Date().getTimezoneOffset / 60)}00
NAME:${data.title}
X-WR-CALNAME:${data.title}
BEGIN:VEVENT
UID: ${mDateStart.format("YYYYMMDDTHHmmss")}-${data.title}@AgentsPortal,
SEQUENCE:0
DTSTAMP:${mDateStart.format("YYYYMMDDTHHmmss")}
DTSTART:${mDateStart.format("YYYYMMDDTHHmmss")}
DTEND:${mDateEnd.format("YYYYMMDDTHHmmss")}
SUMMARY:${parseHTMLText(data.details, "div")}
LOCATION:${data.location}
END:VEVENT
END:VCALENDAR`;
    downloadFile(data.title + ".ics", cal, mime);
  }

  handleAddToCalendar = event => {
    if (this.state.isAddToCalMenuOpen) {
      this.setState({ isAddToCalMenuOpen: false });
    } else {
      const element = event.currentTarget.getBoundingClientRect();
      this.setState({
        isAddToCalMenuOpen: true,
        contextMenuXY: {
          left: element.left + 22.5,
          top: element.top - 192,
        },
      });
    }
  };

  localizer = momentLocalizer(moment);
  render() {
    const {
      selectedEvent,
      seeMore,
      seeMoreEvents,
      eventsList,
      isAddToCalMenuOpen,
      contextMenuXY,
    } = this.state;
    const { brandById, theme } = this.props;
    return (
      <div className="Calendar animated fadeIn">
        <Calendar
          showMultiDayTimes
          eventPropGetter={event => ({
            style: { backgroundColor: event.backgroundColor },
          })}
          style={{ color: theme.primary, borderColor: theme.secondary }}
          selectable
          onSelectEvent={this.handleEventSelected}
          onShowMore={this.handleSeeMoreSelected}
          onNavigate={this.handleOnNavigate}
          components={this.components}
          formats={this.formats}
          localizer={this.localizer}
          views={["month", "week"]}
          events={eventsList}
          startAccessor="start"
          endAccessor="end"
        />
        {(selectedEvent || seeMore) && (
          <ContextElement
            elType="div"
            className="Calendar__modal animated fadeIn"
            config={{ backgroundColor: THEME_KEYS.PRIMARY }}
          >
            {selectedEvent && (
              <SelectedEventComponent
                title={selectedEvent.title}
                type={selectedEvent.type}
                location={selectedEvent.location}
                selectEvent={() => this.handleEventSelected(null)}
                startDate={selectedEvent.start}
                endDate={selectedEvent.end}
                details={selectedEvent.details}
                handleAddToCalendar={this.handleAddToCalendar}
              />
            )}
            {seeMore && (
              <SeeMoreComponent
                onSelectEvent={this.handleEventSelected}
                handleClose={this.handleMoreEventsClose}
                brandById={brandById}
                events={seeMoreEvents}
              />
            )}
          </ContextElement>
        )}
        {isAddToCalMenuOpen && (
          <FileRightClick
            x={contextMenuXY.left}
            y={contextMenuXY.top}
            isContextMenuOpen={isAddToCalMenuOpen}
            setIsContextMenuOpen={this.setIsAddToCalMenuOpen}
            isFolder={false}
            options={this.addToCalOptions}
          />
        )}
      </div>
    );
  }
}

CalendarComponent.defaultProps = {
  theme: {},
};

CalendarComponent.propTypes = {
  getEvents: propTypes.func,
  history: propTypes.object,
  match: propTypes.object,
  events: propTypes.func,
  brandById: propTypes.func,
  theme: propTypes.object,
};

export default withTheme(CalendarComponent);
