import React from "react";
import propTypes from "prop-types";

const FilePreviewComponent = ({ file, className }) => {
  const ext = file.file_extension ? file.file_extension.toLowerCase() : "unknown";
  switch (ext) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return <img src={file.document} className={className} alt={file.name} />;
    case "mp4":
    case "webm":
    case "ogg":
      return (<video className={className} controls autoPlay>
        <source src={file.document} />
      </video>);
    case "mp3":
    case "wav":
      return (<audio className={className} controls>
        <source src={file.document} />
      </audio>);
    case "pdf":
      return (<object
        className={className}
        width="100%" height="100%"
        data={file.document}
        name={file.original_filename}
        type="application/pdf">
        <p>Unable to load a preview for the file:</p>
        <p>{file.name}</p>
        <br />
        <p><button className="FilePreview__openDocument"
          onClick={() => window.open(file.document)}>Click here</button> to open the file directly.</p>
      </object>);
    default:
      return <p className={className}>Unable to load preview for {file.name}</p>;
  }
};

FilePreviewComponent.propTypes = {
  file: propTypes.object,
  className: propTypes.string,
};

export default FilePreviewComponent;
