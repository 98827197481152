import React, { Component } from "react";
import propTypes from "prop-types";
import { IconDownload } from "assets/svg/download";
import { IconLink } from "assets/svg/link";
import { IconShare } from "assets/svg/share";
// import { IconMessenger } from "assets/svg/messenger";
import { IconStar } from "assets/svg/star";
import { IconMessage } from "assets/svg/message";
import { IconWhatsApp } from "assets/svg/whatsapp";
// import { IconWeChat } from "assets/svg/wechat";
import { THEME_KEYS } from "helpers/constants";
import ContextElement from "components/shared/elements/ContextElement";
import isMobile from "helpers/isMobile";
import { errorToast, successToast } from "helpers/toastMsgs";
import { SHAREABLE_LINK_MSG } from "../../../../../../helpers/constants";

class FileRightClick extends Component {
  constructor() {
    super();
    this.state = {
      isShareClicked: false,
      ignoreWindowPosition: false,
      x: 0,
      y: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.x !== prevState.x || nextProps.y !== prevState.y) {
      return {
        isShareClicked: nextProps.isShareClicked,
        x: nextProps.x,
        y: nextProps.y,
        ignoreWindowPosition: nextProps.ignoreWindowPosition || false,
      };
    }
    return prevState;
  }

  getShareUrl = (isWhatsApp) => {
    let url = `${window.location.href}/download/${this.props.rightClickFile.id}`;
    if (this.props.shareUrl) {
      url = this.props.shareUrl;
    }
    if (isWhatsApp) {
      url = url.replace(/\+/g, "%2B");
    }
    return url;
  }

  handleStarClicked = () => {
    const file = this.props.rightClickFile;
    this.props.setQuickAccessFile(
      Object.assign(file, {
        type: file.document_type === undefined ? "directory" : "document",
        isToSet: !file.isQuickAccess,
      })
    );
    this.props.setIsContextMenuOpen(false);
  };

  handleShareClicked = () => {
    this.setState({ isShareClicked: true });
  };

  handleDownloadClicked = () => {
    this.props.downloadFolder(this.props.rightClickFile);
    this.props.setIsContextMenuOpen(false);
  };

  handleShareMenuClose = shareFnLink => {
    let wnd = null;
    if (shareFnLink) {
      wnd = window.open(shareFnLink);
    }
    this.props.setIsContextMenuOpen(false);
    if (this.props.setIsShareClicked) {
      this.props.setIsShareClicked(false);
    }
    this.setState({ isShareClicked: false });
    return wnd;
  };

  handleClickedOff = () => {
    this.props.setIsContextMenuOpen(false);
  };

  shareButtons = [
    {
      title: "Get shareable link",
      icon: IconLink,
      fn: () => {
        const url = this.getShareUrl(false);
        this.handleShareMenuClose();
        navigator.clipboard.writeText(
          url
        ).then(function() {
          // Promise resolved successfully.
          successToast(SHAREABLE_LINK_MSG);
        }, function() {
          // Promise rejected.
          errorToast(SHAREABLE_LINK_MSG);
        });
      },
    },
    {
      title: "Share via email",
      icon: IconMessage,
      fn: () => {
        const url = this.getShareUrl(false);
        const mailtoLink = `mailto:?subject=A shared document from Agents Portal&body=Link to shared file: ${url}`;
        const wnd = this.handleShareMenuClose(mailtoLink);
        setTimeout(() => wnd.close(), 300);
      },
    },
    {
      title: "Share via WhatsApp",
      icon: IconWhatsApp,
      fn: () => {
        const url = this.getShareUrl(true);
        const whatsAppLink = isMobile() ? "whatsapp:/" : "https://web.whatsapp.com";
        this.handleShareMenuClose(
          `${whatsAppLink}/send?text=${url}`
        );
      },
    },
    // {
    //   title: "Share via Messenger",
    //   icon: IconMessenger,
    //   fn: () => {
    //     const url = this.getShareUrl(false);
    //     console.log(`fb-messenger://share?link=${url}&app_id=325296065316177`);
    //     this.handleShareMenuClose(`fb-messenger://share?link=${url}`);
    //   },
    //   viewable: isMobile(),
    // },
  ];

  rightClickOptions = rightClickFile => [
    {
      title: `${rightClickFile.isQuickAccess ? "Remove from" : "Add to"} Quick Access`,
      icon: IconStar,
      fn: this.handleStarClicked,
    },
    {
      title: "Share",
      icon: IconShare,
      fn: this.handleShareClicked,
      viewable: !!(rightClickFile.shareable && rightClickFile.document_type !== undefined),
    },
    { title: "Download", icon: IconDownload, fn: this.handleDownloadClicked },
  ];

  calculateMenuOrientation = () => {
    return this.state.ignoreWindowPosition && this.props.position
      ? { top: this.state.position.y, left: this.state.position.x }
      : Object.assign(
        {},
        this.state.x <= (window.innerWidth + 100) / 2
          ? { left: `${this.state.x + 5}px` }
          : { right: `${window.innerWidth - this.state.x - 5}px` },
        this.state.y <= window.innerHeight / 2
          ? { top: `${this.state.y}px` }
          : { bottom: `${window.innerHeight - this.state.y}px` }
      );
  };

  render() {
    const { rightClickFile, isContextMenuOpen } = this.props;
    const { isShareClicked } = this.state;

    const options = this.props.options
      ? this.props.options
      : !isShareClicked && rightClickFile
        ? this.rightClickOptions(rightClickFile)
        : this.shareButtons;

    return (
      <React.Fragment>
        {isContextMenuOpen && (
          <React.Fragment>
            <button onClick={this.handleClickedOff} className="FileRightClick__backdrop" />
            <div
              style={this.calculateMenuOrientation()}
              className={this.state.open ? "FileRightClick FileRightClick--open" : "FileRightClick"}
            >
              {options.map(option => {
                return option.viewable !== false ? (
                  <button key={option.title} onClick={option.fn} className="FileRightClick__button">
                    {option.icon && <ContextElement elType={option.icon} fillOption={THEME_KEYS.PRIMARY} />}
                    <ContextElement
                      elType="p"
                      config={{ color: THEME_KEYS.PRIMARY }}
                      className="FileRightClick__button__text"
                    >
                      {option.title}
                    </ContextElement>
                  </button>
                ) : (
                  <div key={option.icon.name} />
                );
              })}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

FileRightClick.propTypes = {
  isQuickAccess: propTypes.bool,
  position:propTypes.object,
  rightClickFile: propTypes.object,
  isContextMenuOpen: propTypes.bool,
  setIsContextMenuOpen: propTypes.func,
  options: propTypes.array,
  setQuickAccessFile: propTypes.func,
  downloadFolder: propTypes.func,
  isShareClicked: propTypes.bool,
  setIsShareClicked: propTypes.func,
  ignoreWindowPosition: propTypes.bool,
  x: propTypes.number,
  y: propTypes.number,
  shareUrl: propTypes.string,
};

export default FileRightClick;
