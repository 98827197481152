import React, { Component } from "react";
import DocumentSection from "components/shared/documents/DocumentSection";
import ArticleSection from "components/shared/articles/ArticleSection";
import ContactBanner from "./ContactBanner";
import SocialFeed from "./SocialFeed/SocialFeed";
import propTypes from "prop-types";
import BrandDropdown from "components/shared/brandDropdown";
import isMobile from "helpers/isMobile";
import { THEME_KEYS } from "helpers/constants";
import AgentHeader from "./../container/agentHeaderContainer";
import { navigation } from "./../../../../../navigation";
import Loader from "components/shared/loader";

class Dashboard extends Component {
  componentDidMount() {
    this.props.getDashboardData();
  }

  render() {
    const { brand, social_feed: socialFeed } = this.props.dashboardData || {};
    const {
      user,
      usefulInformationList,
      newsList,
      history,
      dashboardDocuments,
      dashboardImage,
      dashboardContact,
      workingDashboard,
      workingNews,
      workingUsefulInfo,
      selectedBrand,
    } = this.props;
    return (
      <div className="Dashboard animated fadeIn">
        {!workingDashboard ? (
          <>
            <div className="Dashboard__brandDropdown">
              <BrandDropdown
                modifiers={[
                  "dark",
                  "long",
                  "small",
                  "no-margin",
                  "arrow-dropdown",
                  "white",
                ]}
              />
            </div>
            <AgentHeader
              brandName={brand && brand.name}
              isFirstLogin={user && user.first_login}
              firstName={user && user.first_name}
              lastName={user && user.last_name}
              imagesrc={dashboardImage}
            />
            {!!dashboardDocuments && (
              <div className="Dashboard__documents">
                <DocumentSection
                  history={this.props.history}
                  selectedBrand={selectedBrand}
                  documentSections={[
                    {
                      title: "Key Documents",
                      docType: "key documents",
                      documents: dashboardDocuments
                        ? dashboardDocuments.keyDocument || []
                        : [],
                    },
                    {
                      title: "Brochures",
                      docType: "brochures",
                      documents: dashboardDocuments
                        ? dashboardDocuments.brochure || []
                        : [],
                    },
                    {
                      title: "Price Lists",
                      docType: "price lists",
                      documents: dashboardDocuments
                        ? dashboardDocuments.priceList || []
                        : [],
                    },
                    {
                      title: "Fact Sheets",
                      docType: "fact sheets",
                      documents: dashboardDocuments
                        ? dashboardDocuments.factSheet || []
                        : [],
                    },
                  ]}
                />
              </div>
            )}
            {!!usefulInformationList && !!usefulInformationList.length &&
              (!workingUsefulInfo ? (
                <ArticleSection
                  brand={brand ? brand.name : null}
                  articleSectionTitle="Useful Information"
                  buttonTitle="Read All Useful Information"
                  buttonColor={THEME_KEYS.SECONDARY}
                  hasBackground
                  articles={usefulInformationList}
                  articleType={navigation.usefulInformation}
                  history={history}
                />
              ) : (
                <div className="Dashboard__loader">
                  <Loader className="Loader--noBG" />
                </div>
              ))}
            {!!newsList && !!newsList.length &&
              (!workingNews ? (
                <ArticleSection
                  brand={brand ? brand.name : null}
                  articleSectionTitle="Latest News & Blogs"
                  buttonTitle="Read All News"
                  buttonColor={
                    isMobile() ? THEME_KEYS.SECONDARY : THEME_KEYS.PRIMARY
                  }
                  articles={newsList}
                  history={history}
                />
              ) : (
                <div className="Dashboard__loader">
                  <Loader className="Loader--noBG" />
                </div>
              ))}
            {!!dashboardContact &&
            <ContactBanner contact={dashboardContact} />
            }
            {socialFeed &&
              socialFeed.instagram &&
              !!socialFeed.instagram.length && (
              <SocialFeed socialFeedItems={socialFeed.instagram} />
            )}
          </>
        ) : (
          <div className="Dashboard__loader">
            <Loader className="Loader--noBG" />
          </div>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  history: propTypes.object,
  getDashboardData: propTypes.func,
  dashboardData: propTypes.object,
  user: propTypes.object,
  dashboardContact: propTypes.object,
  newsList: propTypes.array,
  usefulInformationList: propTypes.array,
  dashboardImage: propTypes.string,
  dashboardDocuments: propTypes.object,
  workingDashboard: propTypes.bool,
  workingNews: propTypes.bool,
  workingUsefulInfo: propTypes.bool,
  selectedBrand: propTypes.object,
};

export default Dashboard;
