import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import DocumentItem from "../DocumentItem";
import ContextElement from "components/shared/elements/ContextElement";
import useDocuments from "hooks/useDocuments";
import DocumentService from "services/documents/documentsService";
import FileRightClick from "../../../../routes/dashboard/pages/digitalResources/components/FileRightClick";
import FilePreview from "../../../../routes/dashboard/pages/digitalResources/components/FilePreview/FilePreview";

const DocumentItemSection = props => {
  const [showPreview, setShowPreview] = useState(false);
  const [currentPreviewFile, setCurrentPreviewFile] = useState({});

  const [contextMenuXY, setContextMenuXY] = useState({});
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [currentShareFile, setCurrentShareFile] = useState({});
  const [shareUrl, setShareUrl] = useState();

  const documentService = new DocumentService();
  const { setDownloadFolder } = useDocuments(documentService);

  useEffect(() => {
    if (props.selectedBrand && Object.keys(currentShareFile).length > 0) {
      const URL = window.location.origin;
      const ROUTE = "digital-resources";
      const NAME = props.selectedBrand.name.split(" ").join("+");
      const DIR = currentShareFile.directory.id;
      const FILE = currentShareFile.id;
      setShareUrl(`${URL}/${ROUTE}/${NAME}/folder/${DIR}/download/${FILE}`);
    }
  }, [props.selectedBrand, currentShareFile]);

  const handleExpandClicked = document => {
    setCurrentPreviewFile(document);
    setShowPreview(true);
  };
  const handleDownloadClicked = document => setDownloadFolder(document);
  const handleShareClicked = (event, document) => {
    setCurrentShareFile(document);
    openContextMenu(event.clientX, event.clientY);
  };

  const openContextMenu = (x, y) => {
    setContextMenuXY({ x, y });
    setIsContextMenuOpen(true);
  };

  const documentComponents = props.documentItemSection.documents.map((document, index) => (
    <DocumentItem
      handleExpandClicked={() => handleExpandClicked(document)}
      handleDownloadClicked={() => handleDownloadClicked(document)}
      handleShareClicked={event => handleShareClicked(event, document)}
      key={`${document.title}-${index}`}
      document={document}
    />
  ));

  const { title } = props.documentItemSection;
  if (!documentComponents.length) return null;
  return (
    <div className="DocumentItemSection">
      <p className="DocumentItemSection__title">{title}</p>
      <ContextElement
        elType="span"
        config={{ backgroundColor: "secondary" }}
        className="DocumentItemSection__seperator"
      />
      <div className="DocumentItemSection__itemWrap">{documentComponents}</div>
      {isContextMenuOpen && (
        <FileRightClick
          x={contextMenuXY.x}
          y={contextMenuXY.y}
          isContextMenuOpen={isContextMenuOpen}
          setIsContextMenuOpen={setIsContextMenuOpen}
          isFolder={false}
          rightClickFile={currentShareFile}
          isShareClicked
          ignoreWindowPosition={props.ignoreWindowPosition}
          shareUrl={shareUrl}
        />
      )}
      {showPreview && (
        <FilePreview
          className="FilePreview--dashboard"
          file={currentPreviewFile}
          handleClosePreview={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

DocumentItemSection.propTypes = {
  title: propTypes.string,
  documentItemSection: propTypes.object,
  ignoreWindowPosition: propTypes.bool,
  selectedBrand: propTypes.object,
};

export default DocumentItemSection;
