import React, { useState, useEffect } from "react";
import FileSection from "./FileSection";
import { viewType } from "./viewTypes";
import HoverComponent from "components/shared/util/Hover";
import isMobile from "helpers/isMobile";
import { IconBack } from "assets/svg/back";
import { IconThreeDots } from "assets/svg/threeDots";
import propTypes from "prop-types";
import { THEME_KEYS, EVENT_KEYS } from "helpers/constants";
import useDocuments from "hooks/useDocuments";
import ContextElement from "components/shared/elements/ContextElement";
import DocumentService from "services/documents/documentsService";
import DocumentsToolBar from "./DocumentsToolBar";
import navigation from "./../../../../../navigation";
import FileRightClick from "./FileRightClick";
import FilePreview from "./FilePreview/FilePreview";
import Loader from "components/shared/loader/Loader";
import useDocumentsSearch from "../../../../../hooks/useDocumentsSearch";
const DigitalResources = props => {
  const documentService = new DocumentService();
  const [fileViewType, setFileViewType] = useState(viewType.FILE);
  const [currentPreviewFileIndex, setCurrentPreviewFileIndex] = useState(-1);
  const [currentPreviewSectionType, setCurrentPreviewSectionType] = useState("documents");
  const [showPreviewControls, setShowPreviewControls] = useState(true);

  // CONTEXT MENU
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isRightClickOnFolder, setIsRightClickOnFolder] = useState(false);
  const [rightClickFile, setRightClickFile] = useState(null);
  const [contextMenuXY, setContextMenuXY] = useState({});
  const [moreFilesDropMenu, setMoreFilesDropMenu] = useState(null);
  const [isShareClicked, setIsShareClicked] = useState(false);

  const {
    documents,
    quickAccess,
    newDirectoryId,
    setNewDirectoryId,
    currentDirectoryId,
    currentBrand,
    setCurrentBrand,
    setQuickAccessFile,
    filePath = [],
    setDownloadFolder,
    filesWorking,
    quickAccessWorking,
    downloadWorking,
    setCurrentPreviewFile,
    currentPreviewFile,
    setShowPreview,
    showPreview,
  } = useDocuments(documentService);

  const { setFilterParam, searchResult, setCurrentBrandSearch } = useDocumentsSearch(documentService);

  const {
    match: {
      params: { id, brand, downloadId },
    },
    history,
    setSelectedBrand,
    selectedBrand,
    brandByName,
  } = props;

  useEffect(() => {
    if (downloadId) {
      setDownloadFolder({
        id: downloadId,
        isFolder: false,
        isFromLink: !!downloadId && !isMobile(),
      });
      setShowPreviewControls(false);
    }
  }, [documents, downloadId, setCurrentPreviewFile, setDownloadFolder, setShowPreview]);

  useEffect(() => {
    if (selectedBrand && (!currentBrand || Number(selectedBrand.id) !== Number(currentBrand.id))) {
      setCurrentBrand(selectedBrand);
      setCurrentBrandSearch(selectedBrand);
    }
  }, [currentBrand, selectedBrand, setCurrentBrand, setCurrentBrandSearch]);

  useEffect(() => {
    if (brand) {
      if (selectedBrand && Number(selectedBrand.id) !== Number(brandByName(brand).id)) {
        setSelectedBrand(brandByName(brand).id);
      }
    }
  }, [brand, selectedBrand, setSelectedBrand, props.match.params.brand, brandByName]);

  useEffect(() => {
    if (id) {
      setNewDirectoryId(id);
    } else {
      setNewDirectoryId("");
    }
  }, [id, setFilterParam, setNewDirectoryId]);

  useEffect(() => {
    const newUrl = navigation.digitalResources +
    (brand || selectedBrand ? `/${(brand ? brand.replace(/ /g, "+") : selectedBrand.name.replace(/ /g, "+"))}` : "") +
    (currentDirectoryId ? `/folder/${currentDirectoryId}` : "");

    if (history.location.pathname !== newUrl) {
      history.push(newUrl);
    }
  }, [brand, history, currentDirectoryId, selectedBrand]);

  useEffect(() => {
    if (!isContextMenuOpen && rightClickFile) {
      setRightClickFile(null);
    }
  }, [isContextMenuOpen, rightClickFile]);

  const createFileHeader = filePath => {
    let componentList = [];
    if (filePath && filePath.length) {
      if (isMobile()) {
        componentList.push(
          <li key={filePath[filePath.length - 1].name} className="DigitalResources__menu__filePath__pathItem">
            <ContextElement elType="p" config={{ color: THEME_KEYS.PRIMARY }}>
              {filePath[filePath.length - 1].name}
            </ContextElement>
          </li>
        );
      } else {
        const filePathToMap = filePath.length >= 3 ? filePath.slice().splice(-2) : filePath;
        if (filePath.length >= 3) {
          componentList.push(
            <li key="root" className="DigitalResources__menu__filePath__pathItem">
              <button
                className="DigitalResources__menu__filePath__pathItem__button"
                onClick={() => setNewDirectoryId("")}
              >
                <ContextElement elType="p" config={{ color: THEME_KEYS.PRIMARY }}>
                  {filePath[0].name || "Digital Resources"}
                </ContextElement>
              </button>
            </li>
          );
        }
        filePathToMap.forEach((pathName, index) => {
          if (filePath.length >= 4 && index === 0) {
            componentList.push(
              <li key="overflow" className="DigitalResources__menu__filePath__pathItem">
                <button
                  className="DigitalResources__menu__filePath__pathItem__button"
                  onClick={handleMoreFilesClicked}
                >
                  <HoverComponent wrapperClassName="DigitalResources__menu__filePath__pathItem__button__more"
                    defaultFill={THEME_KEYS.PRIMARY}>
                    <IconThreeDots />
                  </HoverComponent>
                </button>
              </li>
            );
          }
          componentList.push(
            <li key={pathName.id} className="DigitalResources__menu__filePath__pathItem">
              <button
                className="DigitalResources__menu__filePath__pathItem__button"
                onClick={() => {
                  if (pathName.id !== newDirectoryId) {
                    setNewDirectoryId(pathName.id);
                  }
                }}
              >
                <ContextElement elType="p" config={{ color: THEME_KEYS.PRIMARY }}>
                  {pathName.name}
                </ContextElement>
              </button>
            </li>
          );
        });
      }
    }
    return componentList;
  };

  const handleViewChangeClicked = event => {
    setFileViewType(viewType[event.currentTarget.value]);
  };

  const handleSearchSubmit = event => {
    if (event.key === EVENT_KEYS.ENTER) {
      setFilterParam(event.currentTarget.value.trim());
    }
  };

  const handleInputChange = event => {
    if (event.currentTarget.value.length === 0) {
      setFilterParam("");
    }
  };

  const handleMoreFilesClicked = event => {
    openContextMenu(event.clientX, event.clientY);
    setMoreFilesDropMenu(
      filePath.slice(1, filePath.length - 2).map(path => {
        return {
          title: path.name,
          fn: () => {
            setNewDirectoryId(path.id);
            setIsContextMenuOpen(false);
            setMoreFilesDropMenu(null);
          },
        };
      })
    );
  };

  const handleMobileBackClicked = () => {
    if (filePath.length > 1) {
      setNewDirectoryId(filePath[filePath.length - 2].id);
    } else {
      props.history.push(navigation.home);
    }
  };

  const handleFolderClicked = (event, file, isShare = false) => {
    if (event.nativeEvent && event.nativeEvent.which !== 3) {
      if (file.thumbnail === undefined && file.docType === undefined) {
        setFilterParam("");
        setNewDirectoryId(file.id);
      }
    } else {
      setIsRightClickOnFolder(true);
      setRightClickFile(file);
      setIsShareClicked(isShare);
      openContextMenu(event.clientX, event.clientY);
    }
  };

  const handleFileDoubleClick = (file, fileIndex, sectionType) => {
    setShowPreview(true);
    setCurrentPreviewFile(file);
    setCurrentPreviewFileIndex(fileIndex);
    setCurrentPreviewSectionType(sectionType);
  };
  const handleClosePreview = () => {
    setShowPreview(false);
    setShowPreviewControls(true);
  };
  const returnFileList = () => {
    switch (currentPreviewSectionType) {
      case "quickAccess":
        return quickAccess;
      case "documents":
      default:
        return searchResult || documents;
    }
  };
  const handlePreviewNext = () => {
    let fileList = returnFileList();
    if (currentPreviewFileIndex < fileList.length - 1) {
      const nextFile = currentPreviewFileIndex + 1;
      setCurrentPreviewFileIndex(nextFile);
      setCurrentPreviewFile(fileList[nextFile]);
    }
  };
  const handlePreviewPrevious = () => {
    let fileList = returnFileList();
    if (currentPreviewFileIndex > 0) {
      const prevFile = currentPreviewFileIndex - 1;
      setCurrentPreviewFileIndex(prevFile);
      setCurrentPreviewFile(fileList[prevFile]);
    }
  };

  const openContextMenu = (x, y) => {
    setContextMenuXY({ x, y });
    setIsContextMenuOpen(true);
  };
  return (
    <div className="DigitalResources animated fadeIn">
      <div className="DigitalResources__menu">
        {isMobile() && (
          <button className="DigitalResources__menu__mobileBack" onClick={handleMobileBackClicked}>
            <HoverComponent defaultFill="#000" hoverFill={THEME_KEYS.SECONDARY}>
              <IconBack />
            </HoverComponent>
          </button>
        )}
        <ContextElement elType="ul" className="DigitalResources__menu__filePath" config={{ color: THEME_KEYS.PRIMARY }}>
          {createFileHeader(filePath)}
        </ContextElement>
        <DocumentsToolBar
          fileViewType={fileViewType}
          handleInputChange={handleInputChange}
          handleSearchSubmit={handleSearchSubmit}
          handleViewChangeClicked={handleViewChangeClicked}
        />
      </div>
      {filePath.length <= 1 && !searchResult && (
        <FileSection
          folderClicked={handleFolderClicked}
          title="Quick Access"
          selectedViewType={fileViewType}
          files={quickAccess}
          downloadFolder={setDownloadFolder}
          handleFileDoubleClick={handleFileDoubleClick}
          sectionType="quickAccess"
          working={quickAccessWorking}
        />
      )}
      <FileSection
        folderClicked={handleFolderClicked}
        title={"Files"}
        selectedViewType={fileViewType}
        files={searchResult || documents}
        downloadFolder={setDownloadFolder}
        handleFileDoubleClick={handleFileDoubleClick}
        sectionType="documents"
        working={filesWorking}
      />
      {isContextMenuOpen && (
        <FileRightClick
          x={contextMenuXY.x}
          y={contextMenuXY.y}
          isContextMenuOpen={isContextMenuOpen}
          setIsContextMenuOpen={setIsContextMenuOpen}
          setQuickAccessFile={setQuickAccessFile}
          isFolder={isRightClickOnFolder}
          rightClickFile={rightClickFile}
          options={moreFilesDropMenu}
          downloadFolder={setDownloadFolder}
          isShareClicked={isShareClicked}
          setIsShareClicked={setIsShareClicked}
        />
      )}
      {showPreview && (
        <FilePreview
          file={currentPreviewFile}
          handleClosePreview={handleClosePreview}
          handlePreviewNext={handlePreviewNext}
          handlePreviewPrevious={handlePreviewPrevious}
          showControls={showPreviewControls}
        />
      )}
      {downloadWorking && (
        <div className="DigitalResources__downloadLoader">
          <Loader className="Loader--noBG" />
        </div>
      )}
    </div>
  );
};

DigitalResources.propTypes = {
  selectedBrand: propTypes.object,
  history: propTypes.object,
  match: propTypes.object,
  setSelectedBrand: propTypes.func,
  brandByName: propTypes.func,
};

export default DigitalResources;
